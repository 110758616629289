@import "../fonts/fonts.44cea94f.css";
@import "style.e93aa29d.css";

canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: inset(0 0 0 0);
  transition: all 500ms ease-out;
  opacity: 0;
  transform: scale(0.7);
}

#canvas > * {
  position: absolute;
  width: 100%;
  height: 100%;
}
