@font-face {
    font-family: 'Px Grotesk';
    src: url('PxGroteskRegular.woff2') format('woff2'),
        url('PxGroteskRegular.woff') format('woff'),
        url('PxGroteskRegular.svg#PxGroteskRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Px Grotesk';
    src: url('PxGrotesk-BoldItalic.woff2') format('woff2'),
        url('PxGrotesk-BoldItalic.woff') format('woff'),
        url('PxGrotesk-BoldItalic.svg#PxGrotesk-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Px Grotesk';
    src: url('PxGrotesk-LightItalic.woff2') format('woff2'),
        url('PxGrotesk-LightItalic.woff') format('woff'),
        url('PxGrotesk-LightItalic.svg#PxGrotesk-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Px Grotesk';
    src: url('PxGrotesk-Light.woff2') format('woff2'),
        url('PxGrotesk-Light.woff') format('woff'),
        url('PxGrotesk-Light.svg#PxGrotesk-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Px Grotesk Screen';
    src: url('PxGrotesk-Screen.woff2') format('woff2'),
        url('PxGrotesk-Screen.woff') format('woff'),
        url('PxGrotesk-Screen.svg#PxGrotesk-Screen') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Px Grotesk';
    src: url('PxGrotesk-Bold.woff2') format('woff2'),
        url('PxGrotesk-Bold.woff') format('woff'),
        url('PxGrotesk-Bold.svg#PxGrotesk-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Px Grotesk';
    src: url('PxGrotesk-RegularItalic.woff2') format('woff2'),
        url('PxGrotesk-RegularItalic.woff') format('woff'),
        url('PxGrotesk-RegularItalic.svg#PxGrotesk-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

